import React from "react";
import Logo from "../assets/NursesRUsLogo.png";
import KimWilliamsImage from "../assets/KimWilliams.jpg";

export const App = () => {
  return (
    <div className="container max-w-full h-screen xxxxxbg-gray-900 items-center">
      <section className="xxxxxbg-purple-100 mx-auto p-8 md:flex md:max-w-6xl">
        <div className="block md:w-2/3 md:mr-8">
          <img src={Logo} className="rounded-lg" alt="Nurses R Us, LLC. logo" />
          <h1 className="mt-2 font-bold text-5xl">Nurses R Us, LLC.</h1>
          <h2 className="uppercase font-bold text-3xl text-blue-700">Service That You Can Trust</h2>
          <p className="text-lg tracking-wide leading-loose mt-8">Nurses R Us, LLC was established in October 2019. Our motto is to provide facilities with Caring Compassionate, Caregivers. Our top five nursing values are: Human dignity, Responsibility, Caring, Respect and Compassion. We are a company that strives to embody the professionalism of nursing as we deliver high quality health care in everything we do.</p>
          <p className="text-lg tracking-wide leading-loose mt-8">Nurses R Us, LLC welcomes all health care entities as we are reaching to service every aspect of the industry. As you all may know, the healthcare field is not getting smaller, it is one of the largest industries in the world. It is our pleasure to be a part of this expanding conglomerate. Here at Nurses R Us, LLC, we will deliver quality services for our client’s everyday needs.</p>
        </div>
        <div className=" block mt-8 md:w-1/3 md:my-auto">
          <img src={KimWilliamsImage} className="rounded-lg" alt="Kim Williams" />
          <p className="mt-3 font-bold">Kim Williams</p>
          <p className="mt-2">Owner of Operations</p>
          <p>LPN,NRCPT</p>
          <p>
            <a href="mailto:KWilliams@NursesRUsLLC.com" className="text-blue-700">KWilliams@NursesRUsLLC.com</a>
          </p>
          <p>
            <a href="tel:614-897-4559" className="text-blue-700">614-897-4559</a>
          </p>
        </div>
      </section>
        <section className="max-w-full bg-yellow-200 flex-1">
          <div className="p-5"><p className="text-center italic">Pardon the dust, we're revamping our website.</p></div>
    </section>
    </div>
  );
};
